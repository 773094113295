import React, { Component } from 'react';
import logo from '../../assets/img/AccountingLogo.png';
import profiledummyimg from '../../assets/img/img1.jpeg';
import { Dropdown, Modal } from 'react-bootstrap';
import api from "../../utils/apiClient";
import moment from 'moment';
import CommonConfig from '../../utils/constant';
import Swal from 'sweetalert2'
import '../../assets/css/stylesheet.scss';
import Dashboard from '../Dashboard/Dashboard.js'
import excelToJson from 'convert-excel-to-json';
import { OutTable, ExcelRenderer } from 'react-excel-renderer';
import Select from 'react-select';

const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
	<span
		ref={ref}
		onClick={(e) => {
			e.preventDefault();
			onClick(e);
		}}
	>
		{children}
	</span>
));

const initialState = {
	Menus: [],
	ProductNo: '',
	showLedgerModal: false,
	showProductModal: false,
	showProductStatementModal: false,
	showTaxModal: false,
	showProfileModal: false,
	LedgerList: [],
	ProductList: [],
	TaxtList: [],
	ProductByTransactionIDList: [],
	ProductStatementList: [],
	UserName: "",
	Contact_No: "",
	Start_Date: "",
	end_Date: "",
	Sub_plan: "",
	ProductStatementArr: [],
	ProductName: '',
	ProductID: '',
	TaxName: '',
	PartyName: '',
	TaxID: '',
	ProductID: '',
	PartyName: '',
	ContactNo: '',
	OriginalContactNo: '',
	ProductName: '',
	ProductNo: '',
	TaxName: '',
	TaxPercentage: '',
	showEditTaxModal: false,
	showEditProductModal: false,
	showEditLedgerModal: false,
	LedgerSearchString: '',
	ProductSearchString: '',
	TaxSearchString: '',
	IsDashboardSelected: false,
	showPasswordChange: false,
	showProfile: true,
	CurrentPassword: '',
	NewPassword: '',
	rows: null,
	cols: null,
	PartyID: '',
	importData: [],
	showImportModal: false,
	ledgerID: '',
	ledgerAccess: [],
	creditAccess: [],
	debitAccess: [],
	productAccess: [],
	taxAccess: [],
	userAccess: [],
	importAccess: [],
	ctrlArray: [],
	total: 0,
	checked: false,
	salesTotal: 0,
	closingBal: 0,
	oldPin: '',
	newPin: ''
}

export default class Header extends Component {
	constructor(props) {
		super(props);
		this.state = initialState;
	}

	componentDidMount() {
		if (localStorage.getItem('IsUserLocked')) {
			this.props.history.push('/user/lockscreen')
		}
		this.getLedgerList();
		this.getProductList();
		this.getTaxList();
		this.getProfileDetail();
		this.getUserMenu();
		document.addEventListener('keydown', this.handleKeyboardEvents);
	}


	handleKeyboardEvents = (event) => {
		var controllArray = this.state.ctrlArray;

		if (controllArray.length < 2) {
			controllArray.push(event.which || event.keyCode)
			this.setState({ ctrlArray: controllArray });
		} else {
			this.setState({ ctrlArray: [] });
			controllArray = [];
		}
		if (controllArray.length > 1) {
			if (controllArray[0] == 9 && controllArray[1] == 76) {
				this.handleOpen("Ledger");
			}
		}
		if (controllArray.length > 1) {
			if (controllArray[0] == 9 && controllArray[1] == 80) {
				this.handleOpen("Product");
			}
		}
		if (controllArray.length > 1) {
			if (controllArray[0] == 9 && controllArray[1] == 81) {
				this.handleLogOut();
			}
		}
	}
	getProductStatement = (ProductName) => {


		this.setState({ showProductStatementModal: true })
		let data = {
			ProductName: ProductName,
			UserID: CommonConfig.loginData().contact_no,
		}
		try {
			api.post("product/getProductStatement", data).then(res => {

				if (res.success) {

					this.setState({
						ProductStatementArr: res.data,

					})

				}
			}).catch(err => { })
		}
		catch (err) { }
	}

	getProfileDetail = () => {

		let data = {
			UserID: CommonConfig.loginData().contact_no,
		}
		try {
			api.post("profile/getProfileDetails", data).then(res => {
				if (res.success) {
					this.setState({
						UserName: res.data[0].username,
						Contact_No: res.data[0].contact_no,
						Start_Date: res.data[0].start_date,
						end_Date: res.data[0].end_date,
						Sub_plan: res.data[0].subscription_plan,

					})
				}
			}).catch(err => { })
		}
		catch (err) { }
	}

	handleLogOut = () => {
		window.location.href = window.location.origin + '/user/login'
		localStorage.removeItem('loginData');
		localStorage.removeItem('isAuthorized');
	}

	getLedgerList = () => {

		let data = {
			UserID: CommonConfig.loginData().contact_no,
		}
		try {
			api.post("ledger/ViewAndLedger", data).then(res => {
				if (res.success) {
					this.setState({
						LedgerList: res.data
					})
				}
			}).catch(err => { })
		}
		catch (err) { }
	}

	getLedgerListByID = (LedgerID) => {

		this.setState({ showEditLedgerModal: true })
		setTimeout(() => {
			this.innerRef.focus();
		}, 1)
		let data = {
			UserID: CommonConfig.loginData().contact_no,
			LedgerID: LedgerID,
		}
		try {
			api.post("ledger/getLedgerListByID", data).then(res => {
				if (res.success) {
					this.setState({

						PartyName: res.data[0].ledger_username,
						ContactNo: res.data[0].ledger_user_id,
						OriginalContactNo: res.data[0].ledger_user_id,
						ledgerID: res.data[0].ledger_id

					})
				}
			}).catch(err => { })
		}
		catch (err) { }
	}

	getProductListByID = (ProductID) => {

		this.setState({ showEditProductModal: true })
		setTimeout(() => {
			this.innerRef.focus();
		}, 1)

		let data = {
			UserID: CommonConfig.loginData().contact_no,
			ProductID: ProductID,
		}
		try {
			api.post("product/getProductListByID", data).then(res => {
				if (res.success) {
					this.setState({
						ProductName: res.data[0].product_name,
						ProductNo: res.data[0].product_number,
						ProductID: ProductID,
					})
				}
			}).catch(err => { })
		}
		catch (err) { }
	}

	getTaxListByID = (TaxID) => {
		this.setState({ showEditTaxModal: true })
		setTimeout(() => {
			this.innerRef.focus();
		}, 1)
		let data = {
			UserID: CommonConfig.loginData().contact_no,
			TaxID: TaxID,
		}
		try {
			api.post("tax/getTaxListByID", data).then(res => {
				if (res.success) {
					this.setState({
						TaxName: res.data[0].tax_name,
						TaxPercentage: res.data[0].tax_percent,
						TaxID: TaxID
					})
				}
			}).catch(err => { })
		}
		catch (err) { }
	}

	getProductList = () => {

		let data = {
			UserID: CommonConfig.loginData().contact_no,
		}
		try {
			api.post("product/getProductList", data).then(res => {
				if (res.success) {
					this.setState({
						ProductList: res.data
					})
				}
			}).catch(err => { })
		}
		catch (err) { }
	}

	getTaxList = () => {

		let data = {
			UserID: CommonConfig.loginData().contact_no,
		}
		try {
			api.post("tax/ViewTax", data).then(res => {
				if (res.success) {
					this.setState({
						TaxtList: res.data
					})
				}
			}).catch(err => { })
		}
		catch (err) { }
	}
	handleOpen = (type) => {
		document.body.click();

		if (type === "Ledger") {
			setTimeout(() => {
				this.innerRef.focus();
			}, 1)
			this.setState({ showLedgerModal: true })
		}
		if (type === "Product") {
			setTimeout(() => {
				this.innerRef.focus();
			}, 1)
			this.setState({ showProductModal: true })
		}
		if (type === "Tax") {
			setTimeout(() => {
				this.innerRef.focus();
			}, 1)
			this.setState({ showTaxModal: true })
		}
		if (type === "Import") {
			this.setState({ showImportModal: true })
		}
		if (type === "Profile") {
			this.setState({ showProfileModal: true })
		}
		if (type === "EditTax") {
			setTimeout(() => {
				this.innerRef.focus();
			}, 1)
			this.setState({ showEditTaxModal: true })
		}

		if (type === "EditLedger") {
			setTimeout(() => {
				this.innerRef.focus();
			}, 1)
			this.setState({ showEditLedgerModal: true })
		}
		if (type === "ChangePin") {

			this.setState({ showChangePinModal: true })
		}

	}

	handleClose = (type) => {
		if (type === "Ledger") {
			this.setState({ showLedgerModal: false })
		}
		if (type === "Product") {
			this.setState({ showProductModal: false })
		}
		if (type === "Tax") {
			this.setState({ showTaxModal: false })
		}
		if (type === "Import") {
			this.setState({ showImportModal: false })
		}
		if (type === "Profile") {
			this.setState({ showProfileModal: false })
		}
		if (type === "ProductStatement") {
			this.setState({ showProductStatementModal: false })
		}
		if (type === "EditTax") {
			this.setState({ showEditTaxModal: false })
		}
		if (type === "EditProduct") {
			this.setState({ showEditProductModal: false })
		}
		if (type === "EditLedger") {
			this.setState({ showEditLedgerModal: false })
		}
		if (type === "ChangePin") {

			this.setState({ showChangePinModal: false, oldPin: '', newPin: '' })
		}
	}
	handleLedger = async (e, pContactNo, ptype) => {
		let data = {
			UserID: CommonConfig.loginData().contact_no,
			partyName: this.state.PartyName,
			contactNo: pContactNo,
			originalContactNo: this.state.OriginalContactNo,
			ptype: ptype,
			LedgerID: this.state.ledgerID
		}
		try {
			await api.post("ledger/AddEditDeleteLedger", data).then(async res => {
				if (res.success) {
					CommonConfig.showMessage(res.data[0].vMessage, res.data[0].vMessageType);

					if (res.data[0].vMessageType == "success") {
						this.setState(initialState);
						// this.props.history.push({
						// 	state: { IsDashboardSelected: true }
						// })
						this.getLedgerList();
						this.getProductList();
						this.getTaxList();
						this.getProfileDetail();
						this.getUserMenu();
						this.setState({ IsDashboardSelected: true })
						this.props.history.push('/dashboard')


					}
				}
			}).catch(err => { })
		}
		catch (err) { }
	}
	submitLedger = async (e, pContactNo, ptype) => {
		e.preventDefault();
		if (ptype != 'Delete') {

			if (CommonConfig.isEmpty(this.state.PartyName) && CommonConfig.isEmpty(this.state.ContactNo)) {

				CommonConfig.showMessage('All field are required', 'error');
			}
			else {
				await this.handleLedger(e, pContactNo, ptype);
			}
		}
		else {
			await this.handleLedger(e, pContactNo, ptype);
		}
	}

	submitTax = (e, ptype) => {
		e.preventDefault();
		let data = {
			UserID: CommonConfig.loginData().contact_no,
			TaxID: ptype === "Add" ? '' : this.state.TaxID,
			TaxName: this.state.TaxName,
			TaxPercent: this.state.TaxPercentage,
			ptype: ptype,
		}
		try {
			api.post("tax/AddEditDeleteTax", data).then(res => {
				if (res.success) {
					CommonConfig.showMessage(res.data[0].vMessage, res.data[0].vMessageType);
					if (res.data[0].vMessageType == "success") {
						this.setState(initialState)
						// this.props.history.push({
						// 	state: { IsDashboardSelected: true }
						// })
						this.getLedgerList();
						this.getProductList();
						this.getTaxList();
						this.getProfileDetail();
						this.getUserMenu();
						// this.setStatte({ IsDashboardSelected: true })
						this.props.history.push('/dashboard')
					}
				}
			}).catch(err => { })
		}
		catch (err) { }
	}

	handleProduct = async (e, ptype) => {
		let data = {
			ProductID: ptype === "Add" ? '' : this.state.ProductID,
			UserID: CommonConfig.loginData().contact_no,
			ProductName: this.state.ProductName,
			ProductNo: this.state.ProductNo,
			ptype: ptype,
		}
		try {
			await api.post("product/AddEditDeleteProduct", data).then(res => {
				if (res.success) {
					CommonConfig.showMessage(res.data[0].vMessage, res.data[0].vMessageType);
					if (res.data[0].vMessageType == "success") {
						this.setState(initialState)
						// this.props.history.push({
						// 	state: { IsDashboardSelected: true }
						// })
						this.getLedgerList();
						this.getProductList();
						this.getTaxList();
						this.getProfileDetail();
						this.getUserMenu();
						// this.setStatte({ IsDashboardSelected: true })
						this.props.history.push('/dashboard')
					}
				}
			}).catch(err => { })
		}
		catch (err) { }
	}
	submitProduct = async (e, ptype) => {
		e.preventDefault();
		if (ptype != 'Delete') {

			if (CommonConfig.isEmpty(this.state.ProductName) && CommonConfig.isEmpty(this.state.ProductNo)) {
				CommonConfig.showMessage('All field are required', 'error');
			}
			else {
				await this.handleProduct(e, ptype)
			}
		}
		else {
			await this.handleProduct(e, ptype)
		}
	}
	// ===========================================================================Changepassword
	handlePwdChange = (e, type) => {
		if (type === "CurrentPassword") {
			this.setState({ CurrentPassword: e.target.value })
		}
		if (type === "ConfirmPassword") {
			this.setState({ ConfirmPassword: e.target.value })
		}
		if (type === "NewPassword") {
			this.setState({ NewPassword: e.target.value })
		}
	}
	handleChangePassword = (e) => {
		e.preventDefault();

		let data = {
			UserID: CommonConfig.loginData().contact_no,
			CurrentPassword: this.state.CurrentPassword,
			NewPassword: this.state.NewPassword === this.state.ConfirmPassword ? this.state.ConfirmPassword : " ",
		}
		try {
			api.post("authentication/userChangePassword", data).then(res => {

				if (res.success) {
					CommonConfig.showMessage(res.message, res.messagetype);
					this.setState({ showProfileModal: false, })
				} else {
					CommonConfig.showMessage(res.message, res.messagetype);
					this.setState({ ConfirmPassword: "", CurrentPassword: "", NewPassword: '', showProfileModal: true })
				}
			}).catch(err => { })
		}
		catch (err) { }
	}
	// ===========================================================================ChangePassword
	handleChange = (e, type) => {
		if (type == "PartyName") {
			this.setState({ PartyName: e.target.value })
		}
		else if (type == "ContactNo") {
			if (CommonConfig.isEmpty(e.target.value)) {
				this.setState({ ContactNo: e.target.value })
			}
			else if (!e.target.value.match(CommonConfig.RegExp.number)) {
				this.setState({ ContactNo: this.state.ContactNo })
			}
			else {
				this.setState({ ContactNo: e.target.value })
			}
		}
		else if (type == "ProductName") {
			this.setState({ ProductName: e.target.value })
		}
		else if (type == "ProductNo") {
			this.setState({ ProductNo: e.target.value })
		}
		else if (type == "TaxName") {
			this.setState({ TaxName: e.target.value })
		}
		else if (type == "TaxPercentage") {
			if (CommonConfig.isEmpty(e.target.value)) {
				this.setState({ TaxPercentage: e.target.value })
			}
			else if (!e.target.value.match(CommonConfig.RegExp.onlyDecimal)) {
				this.setState({ TaxPercentage: this.state.TaxPercentage })
			}
			else {
				this.setState({ TaxPercentage: e.target.value })
			}
		}
		else if (type === "DeleteProduct") {
			document.body.click()
			CommonConfig.showDeleteConfirm().then((result) => {
				if (result.isConfirmed) {
					this.submitProduct(e, "Delete")
				} else if (result.dismiss === Swal.DismissReason.cancel) {
					this.setState(initialState);
					this.getLedgerList();
					this.getProductList();
					this.getTaxList();
					this.getProfileDetail();
				}
			})
		}
		else if (type === "DeleteTax") {
			document.body.click()
			CommonConfig.showDeleteConfirm().then((result) => {
				if (result.isConfirmed) {
					this.submitTax(e, "Delete")
				} else if (result.dismiss === Swal.DismissReason.cancel) {
					this.setState(initialState);
					this.getLedgerList();
					this.getProductList();
					this.getTaxList();
					this.getProfileDetail();
				}
			})
		}
		else if (type == "oldPin") {
			this.setState({ oldPin: e.target.value })
		}
		else if (type == "newPin") {
			this.setState({ newPin: e.target.value })
		}
	}

	handleFullScreen = (e) => {
		if ((document.fullScreenElement && document.fullScreenElement !== null) || (document.msfullscreenElement && document.msfullscreenElement !== null) || (!document.mozFullScreen && !document.webkitIsFullScreen)) {
			if (document.documentElement.requestFullScreen) {
				document.documentElement.requestFullScreen();
			} else if (document.documentElement.mozRequestFullScreen) {
				document.documentElement.mozRequestFullScreen();
			} else if (document.documentElement.webkitRequestFullScreen) {
				document.documentElement.webkitRequestFullScreen(Element.ALLOW_KEYBOARD_INPUT);
			} else if (document.documentElement.msRequestFullscreen) {
				document.documentElement.msRequestFullscreen();
			}
		} else {
			if (document.cancelFullScreen) {
				document.cancelFullScreen();
			} else if (document.mozCancelFullScreen) {
				document.mozCancelFullScreen();
			} else if (document.webkitCancelFullScreen) {
				document.webkitCancelFullScreen();
			} else if (document.msExitFullscreen) {
				document.msExitFullscreen();
			}
		}
	}

	fileHandler = (event) => {
		if (event.target.files.length) {
			let fileObj = event.target.files[0];
			let fileName = fileObj.name;

			//check for file extension and pass only if it is .xlsx and display error message otherwise
			if (fileName.slice(fileName.lastIndexOf('.') + 1) === "xlsx") {
				ExcelRenderer(fileObj, (err, resp) => {
					if (err) {
						console.log(err);
					}
					else {
						var finalImportData = []
						if (resp.rows.length > 1) {
							// 							0: "Date"
							// 1: "Particulars"
							// 3: "Vch Type"
							// 4: "Vch No."
							// 5: "Debit"
							// 6: "Credit"
							if (resp.rows[0][0] == "Date" && resp.rows[0][1] == "Particulars") {
								resp.rows.splice(0, 1)
								resp.rows.map((x, idx) => {
									if (x.length == 3) {
										finalImportData[finalImportData.length - 1]['Remark'] = x[2]
									} else if (!CommonConfig.isEmpty(x[0]) && !CommonConfig.isEmpty(x[1]) && !CommonConfig.isEmpty(x[2])) {
										finalImportData.push({
											Date: moment(CommonConfig.ExcelDateToJSDate(x[0])).format("YYYY-MM-DD") == "Invalid date" ?
												moment(x[0]).format("YYYY-MM-DD") : moment(CommonConfig.ExcelDateToJSDate(x[0])).format("YYYY-MM-DD"),
											Amount: x.length == 6 ? x[5] : x[6],
											TransactionType: x[1],
											Remark: ""
										})
									}
								})
							} else {
								this.fileImp.value = null
								CommonConfig.showMessage("File format must be same as sample file.", "info");
							}
						}
						console.log("import", finalImportData)
						this.setState({
							importData: finalImportData
						});
					}
				});
			}
			else {
				this.fileImp.value = null
				CommonConfig.showMessage("Only .xlsx file type is supported.", "info");
			}
		}
	}

	validate = () => {
		let IsValid = true;
		if (!this.state.importData.length && CommonConfig.isObjectEmpty(this.state.PartyID)) {
			CommonConfig.showMessage("Import data/Party name is required.", "info");
			IsValid = false
		}
		else if (!this.state.importData.length) {
			CommonConfig.showMessage("Import data is required.", "info");
			IsValid = false
		}
		else if (CommonConfig.isObjectEmpty(this.state.PartyID)) {
			CommonConfig.showMessage("Party name is required.", "info");
			IsValid = false
		}
		return IsValid;
	}

	bulkImportEntry = (e) => {
		e.preventDefault();
		if (this.validate()) {
			let data = {
				UserID: CommonConfig.loginData().contact_no,
				importData: this.state.importData,
				PartyID: CommonConfig.isObjectEmpty(this.state.PartyID) ? '' : this.state.PartyID.value,
			}
			try {
				api.post("ledger/bulkImport", data).then(res => {
					if (res.success) {
						CommonConfig.showMessage(res.data[0].vMessage, res.data[0].vMessageType);
						this.setState(initialState)
						this.props.history.push({
							state: { IsDashboardSelected: true }
						})
						this.getLedgerList();
						this.getProductList();
						this.getTaxList();
						this.getProfileDetail();
					}
				}).catch(err => { })
			}
			catch (err) { }
		}
	}

	getUserMenu = async () => {
		try {
			let data = {
				UserID: CommonConfig.loginData().contact_no
			}
			await api.post("useraccess/getUserMenu", data)
				.then(async (res) => {
					let response = await res;
					if (response.success) {
						this.setState({
							ledgerAccess: response.data[4],
							creditAccess: response.data[2],
							debitAccess: response.data[3],
							productAccess: response.data[5],
							taxAccess: response.data[6],
							userAccess: response.data[1],
							importAccess: response.data[0]

						})

					} else {

					}
				})
				.catch((err) => {

				})

		} catch (err) {

		}
	}
	changePin = async (e) => {
		e.preventDefault();
		if (!CommonConfig.isEmpty(this.state.oldPin) && !CommonConfig.isEmpty(this.state.newPin)) {

			try {
				let data = {
					SecurityUserID: CommonConfig.loginData().contact_no,
					OldPin: this.state.oldPin,
					NewPin: this.state.newPin,

				}
				await api.post("useraccess/changePin", data)
					.then(async (res) => {
						let response = await res;
						if (response.success) {
							this.handleClose('ChangePin');
							CommonConfig.showMessage(response?.data[0]?.Message, 'success');

						} else {
							CommonConfig.showMessage(response?.data[0]?.Message, 'error');
							this.handleClose('ChangePin');
						}
					})
					.catch((err) => {
						console.log('catch err', err)
					})

			} catch (err) {
				console.log('err', err)
			}
		}
		else {
			CommonConfig.showMessage('Old and New pin is required', 'error');
		}
	}
	handleBalanceData = (e, crInput, drInput) => {
		if (e.target.checked) {
			this.state.total += Number(crInput);
		} else {
			this.state.total -= Number(crInput);
		}
		if (e.target.checked) {
			this.state.salesTotal += Number(drInput);
		} else {
			this.state.salesTotal -= Number(drInput);
		}
		this.setState({ checked: e.target.checked })

	}
	render() {
		const { importAccess, ledgerAccess, creditAccess, debitAccess, productAccess, taxAccess,
			userAccess, MenuAccess, Menus, LedgerSearchString, ProductSearchString, NewPassword, CurrentPassword, TaxSearchString, showLedgerModal, ConfirmPassword, showProductModal, showEditTaxModal, showEditProductModal, showEditLedgerModal, showProductStatementModal, showTaxModal, showProfileModal, LedgerList, ProductList, CreditEntry, TaxtList, UserName, ProductStatementArr, Contact_No, Start_Date, end_Date, Sub_plan, PartyName, ContactNo, ProductName, LedgerListByID, ProductListByID, TaxListByID, showPasswordChange, showProfile, ProductID, TaxName, ProductNo, TaxPercentage, showImportModal, checked,
			oldPin, newPin, showChangePinModal } = this.state;

		let allfilterLedger = LedgerList.filter(
			(alllist) => {
				// return alllist.ledger_username.toLowerCase().indexOf(LedgerSearchString.toLowerCase()) !== -1 ||
				// 	alllist.ledger_user_id.toLowerCase().indexOf(LedgerSearchString.toLowerCase()) !== -1;

				return alllist.ledger_username.toLowerCase().indexOf(this.state.LedgerSearchString.toLowerCase()) !== -1 ||
					alllist.ledger_user_id.toString().toLowerCase().indexOf(this.state.LedgerSearchString.toLowerCase()) !== -1;

			}
		)

		let allfilterProduct = ProductList.filter(
			(alllist) => {
				return alllist.product_name.toLowerCase().indexOf(ProductSearchString.toLowerCase()) !== -1 ||
					alllist.pt_id.toString().toLowerCase().indexOf(ProductSearchString.toLowerCase()) !== -1;
			}
		)

		let allfilterTax = TaxtList.filter(
			(alllist) => {
				return alllist.tax_name.toLowerCase().indexOf(TaxSearchString.toLowerCase()) !== -1 ||
					alllist.tax_percent.toString().toLowerCase().indexOf(TaxSearchString.toLowerCase()) !== -1;
			}
		)
		return (
			<div className="dashboard-header">

				<div className="d-flex justify-content-between h-100">
					<div className="d-flex">
						{/* Logo */}
						<div className="dashboard-header-logo mx-2" >
							<img src={logo} alt="" />
						</div>

						{
							ledgerAccess.MenuName == 'Ledger' && ledgerAccess.ViewAccess == 1 ?
								<div className="d-flex h-100 ledger-dropdown">
									<div className="dropdown align-self-center">
										<Dropdown>
											<Dropdown.Toggle as={CustomToggle}>
												<button className="header-icon card-header-btn ledger-btn mx-2" id="ledger" aria-expanded="false" title="Ledger">
													<i className="fas fa-users"></i>
												</button>
											</Dropdown.Toggle>
											<Dropdown.Menu className="dropdown-menu py-0">
												<li className="dropdown-search">
													<div className="d-flex justify-content-between">
														<div className="align-self-center ml-2">
															<div className="card-element mb-0">
																<input type="text" className="p-1" onChange={(e) => this.setState({ LedgerSearchString: e.target.value })} />
															</div>
														</div>
														{ledgerAccess.AddAccess == 1 ?
															<div className="align-self-center">
																<button className="header-icon card-header-btn ledger-btn mx-2" onClick={() => this.handleOpen('Ledger')}>
																	<i className="fas fa-plus "></i>
																</button>
															</div>
															: null}
													</div>
												</li>
												<div id="ledger_box">
													{allfilterLedger.map(x => {
														return (
															<li className="py-2 profile-drop-button">
																<div className="d-flex justify-content-between">
																	<div className="d-flex align-items-center">
																		<div className="ledger-profile align-self-center mx-1">
																			<img src={profiledummyimg} alt="User profile" />
																		</div>
																		<div className="cursor-pointer px-2" data-toggle="modal" data-target="#userStatement">
																			<span className="text-nowrap mb-3" style={{ fontWeight: "500" }} onClick={() => {
																				// debugger
																				// this.setState({ IsDashboardSelected: true })
																				this.props.history.push({
																					state: { LedgerID: x.ledger_user_id }
																				})
																			}}>{x.ledger_username}</span>
																			<p className="mt-2 mb-0 list-ledger-name">
																				<span className="secondary-color text-nowrap">
																					<i className="fas fa-phone-alt"></i>
																					{x.ledger_user_id}
																				</span>
																			</p>
																		</div>
																	</div>
																	<div className="text-nowrap">
																		{ledgerAccess.EditAccess == 1 ?
																			<button className="header-icon ledger-btn mx-1" data-toggle="modal" data-target="#addSection">
																				<i className="fas fa-pencil-alt" onClick={(e) => this.getLedgerListByID(x.ledger_user_id)}></i>
																			</button>
																			: null}
																		{ledgerAccess.DeleteAccess == 1 ?
																			<button className="header-icon ledger-btn mx-1" onClick={(e) => {
																				document.body.click()
																				CommonConfig.showDeleteConfirm().then((result) => {
																					if (result.isConfirmed) {
																						this.submitLedger(e, x.ledger_user_id, "Delete")
																					} else if (result.dismiss === Swal.DismissReason.cancel) {
																						this.setState(initialState);
																						this.getLedgerList();
																						this.getProductList();
																						this.getTaxList();
																						this.getProfileDetail();
																					}
																				})
																			}}>
																				<i className="fas fa-trash"></i>
																			</button> : null}
																	</div>
																</div>
															</li>

														)
													})}
												</div>
											</Dropdown.Menu>
										</Dropdown>
									</div>
								</div>
								:
								null
						}
						{/* Ledger */}

						{/* <div className="d-flex h-100 ledger-dropdown">
							<div className="dropdown align-self-center">
								<Dropdown>
									<Dropdown.Toggle as={CustomToggle}>
										<button className="header-icon card-header-btn ledger-btn mx-2" id="ledger" aria-expanded="false" title="Ledger">
											<i className="fas fa-users"></i>
										</button>
									</Dropdown.Toggle>
									<Dropdown.Menu className="dropdown-menu py-0">
										<li className="dropdown-search">
											<div className="d-flex justify-content-between">
												<div className="align-self-center ml-2">
													<div className="card-element mb-0">
														<input type="text" className="p-1" onChange={(e) => this.setState({ LedgerSearchString: e.target.value })} />
													</div>
												</div>
												<div className="align-self-center">
													<button className="header-icon card-header-btn ledger-btn mx-2" onClick={() => this.handleOpen('Ledger')}>
														<i className="fas fa-plus "></i>
													</button>
												</div>
											</div>
										</li>
										<div id="ledger_box">
											{allfilterLedger.map(x => {
												return (
													<li className="py-2 profile-drop-button">
														<div className="d-flex justify-content-between">
															<div className="d-flex align-items-center">
																<div className="ledger-profile align-self-center mx-1">
																	<img src={profiledummyimg} alt="User profile" />
																</div>
																<div className="cursor-pointer px-2" data-toggle="modal" data-target="#userStatement">
																	<span className="text-nowrap mb-3" style={{ fontWeight: "500" }} onClick={() => {
																		// debugger
																		// this.setState({ IsDashboardSelected: true })
																		this.props.history.push({
																			state: { LedgerID: x.ledger_user_id }
																		})
																	}}>{x.ledger_username}</span>
																	<p className="mt-2 mb-0 list-ledger-name">
																		<span className="secondary-color text-nowrap">
																			<i className="fas fa-phone-alt"></i>
																			{x.ledger_user_id}
																		</span>
																	</p>
																</div>
															</div>
															<div className="text-nowrap">
																<button className="header-icon ledger-btn mx-1" data-toggle="modal" data-target="#addSection">
																	<i className="fas fa-pencil-alt" onClick={(e) => this.getLedgerListByID(x.ledger_user_id)}></i>
																</button>
																<button className="header-icon ledger-btn mx-1" onClick={(e) => {
																	document.body.click()
																	CommonConfig.showDeleteConfirm().then((result) => {
																		if (result.isConfirmed) {
																			this.submitLedger(e, x.ledger_user_id, "Delete")
																		} else if (result.dismiss === Swal.DismissReason.cancel) {
																			this.setState(initialState);
																			this.getLedgerList();
																			this.getProductList();
																			this.getTaxList();
																			this.getProfileDetail();
																		}
																	})
																}}>
																	<i className="fas fa-trash"></i>
																</button>
															</div>
														</div>
													</li>

												)
											})}
										</div>
									</Dropdown.Menu>
								</Dropdown>
							</div>
						</div> */}
						{/* : null} */}
						{/* Product */}
						{
							productAccess.MenuName == 'Product' && productAccess.ViewAccess == 1 ?
								<div className="d-flex h-100 ledger-dropdown">
									<div className="dropdown align-self-center">
										<Dropdown>
											<Dropdown.Toggle as={CustomToggle}>
												<button className="header-icon card-header-btn ledger-btn mx-2" id="ledger" aria-expanded="false" title="Product">
													<i className="fas fa-box-open"></i>
												</button>
											</Dropdown.Toggle>
											<Dropdown.Menu className="dropdown-menu py-0">
												<li className="dropdown-search">
													<div className="d-flex justify-content-between">
														<div className="align-self-center ml-2">
															<div className="card-element mb-0">
																<input type="text" className="p-1" onChange={(e) => this.setState({ ProductSearchString: e.target.value })} />
															</div>
														</div>
														{productAccess.AddAccess == 1 ?
															<div className="align-self-center">
																<button className="header-icon card-header-btn ledger-btn mx-2" onClick={() => this.handleOpen('Product')}>
																	<i className="fas fa-plus "></i>
																</button>


															</div> : null}
													</div>
												</li>
												<div id="product_box">
													{allfilterProduct.map(x => {
														return (
															<li className="p-2 profile-drop-button text-nowrap">
																<div className="d-flex justify-content-between">
																	<div data-toggle="modal" data-target="#ProductSummary" className="align-self-center mr-1 cursor-pointer" onClick={() => this.getProductStatement(x.product_name)}>{x.product_name}</div>
																	<div className="align-self-center">
																		{productAccess.EditAccess == 1 ?
																			<button onclick="ProductDetails('New', 'Test', 20)" className="header-icon ledger-btn mx-1" data-toggle="modal" data-target="#addSection">
																				<i className="fas fa-pencil-alt" onClick={(e) => this.getProductListByID(x.pt_id)}></i>
																			</button>
																			: null}
																		{productAccess.DeleteAccess == 1 ?
																			<button onClick={(e) => {
																				this.setState({ ProductID: x.pt_id })
																				this.handleChange(e, "DeleteProduct")
																			}} className="header-icon ledger-btn mx-1">
																				<i className="fas fa-trash"></i>
																			</button>
																			: null}
																	</div>
																</div>
															</li>
														)
													})}
												</div>
											</Dropdown.Menu>
										</Dropdown>
									</div>
								</div>
								:
								null
						}
						{/* <div className="d-flex h-100 ledger-dropdown">
							<div className="dropdown align-self-center">
								<Dropdown>
									<Dropdown.Toggle as={CustomToggle}>
										<button className="header-icon card-header-btn ledger-btn mx-2" id="ledger" aria-expanded="false" title="Product">
											<i className="fas fa-box-open"></i>
										</button>
									</Dropdown.Toggle>
									<Dropdown.Menu className="dropdown-menu py-0">
										<li className="dropdown-search">
											<div className="d-flex justify-content-between">
												<div className="align-self-center ml-2">
													<div className="card-element mb-0">
														<input type="text" className="p-1" onChange={(e) => this.setState({ ProductSearchString: e.target.value })} />
													</div>
												</div>
												<div className="align-self-center">
													<button className="header-icon card-header-btn ledger-btn mx-2" onClick={() => this.handleOpen('Product')}>
														<i className="fas fa-plus "></i>
													</button>


												</div>
											</div>
										</li>
										<div id="product_box">
											{allfilterProduct.map(x => {
												return (
													<li className="p-2 profile-drop-button text-nowrap">
														<div className="d-flex justify-content-between">
															<div data-toggle="modal" data-target="#ProductSummary" className="align-self-center mr-1 cursor-pointer" onClick={() => this.getProductStatement(x.product_name)}>{x.product_name}</div>
															<div className="align-self-center">
																<button onclick="ProductDetails('New', 'Test', 20)" className="header-icon ledger-btn mx-1" data-toggle="modal" data-target="#addSection">
																	<i className="fas fa-pencil-alt" onClick={(e) => this.getProductListByID(x.pt_id)}></i>
																</button>
																<button onClick={(e) => {
																	this.setState({ ProductID: x.pt_id })
																	this.handleChange(e, "DeleteProduct")
																}} className="header-icon ledger-btn mx-1">
																	<i className="fas fa-trash"></i>
																</button>
															</div>
														</div>
													</li>
												)
											})}
										</div>
									</Dropdown.Menu>
								</Dropdown>
							</div>
						</div> */}
						{/* Tax */}
						{
							taxAccess.MenuName == 'Tax' && taxAccess.ViewAccess == 1 ?
								<div className="d-flex h-100 ledger-dropdown">
									<div className="dropdown align-self-center">
										<Dropdown>
											<Dropdown.Toggle as={CustomToggle}>
												<button className="header-icon card-header-btn ledger-btn mx-2" id="ledger" data-toggle="dropdown" aria-expanded="false" title="Tax">
													<i className="fas fa-percentage"></i>
												</button>
											</Dropdown.Toggle>
											<Dropdown.Menu className="dropdown-menu py-0">
												<li className="dropdown-search">
													<div className="d-flex justify-content-between">
														<div className="align-self-center mx-2">
															<div className="card-element mb-0">
																<input type="text" className="p-1" onChange={(e) => this.setState({ TaxSearchString: e.target.value })} />
															</div>
														</div>
														{taxAccess.AddAccess == 1 ?
															<div className="align-self-center">
																<button className="header-icon card-header-btn ledger-btn mx-2" onClick={() => this.handleOpen('Tax')}>
																	<i className="fas fa-plus "></i>
																</button>

															</div> : null}
													</div>
												</li>
												<div id="tax_box">
													{allfilterTax.map(x => {
														return (<li className="p-2 profile-drop-button text-nowrap">
															<div className="d-flex justify-content-between">
																<div className="align-self-center mr-1">{x.tax_name}
																	({x.tax_percent}%)</div>
																<div className="align-self-center">
																	{taxAccess.EditAccess == 1 ?
																		<button onclick="TaxDetails('gst', 12.0, 49)" className="header-icon ledger-btn mx-1" data-toggle="modal" data-target="#addSection">
																			<i className="fas fa-pencil-alt" onClick={(e) => this.getTaxListByID(x.pt_id)}></i>
																		</button> : null}
																	{taxAccess.DeleteAccess == 1 ?
																		<button onClick={(e) => {
																			this.setState({ TaxID: x.pt_id })
																			this.handleChange(e, "DeleteTax")
																		}} className="header-icon ledger-btn mx-1">
																			<i className="fas fa-trash"></i>
																		</button> : null}

																</div>
															</div>
														</li>)
													})}
												</div>
											</Dropdown.Menu>
										</Dropdown>
									</div>
								</div>
								:
								null
						}
						{/* <div className="d-flex h-100 ledger-dropdown">
							<div className="dropdown align-self-center">
								<Dropdown>
									<Dropdown.Toggle as={CustomToggle}>
										<button className="header-icon card-header-btn ledger-btn mx-2" id="ledger" data-toggle="dropdown" aria-expanded="false" title="Tax">
											<i className="fas fa-percentage"></i>
										</button>
									</Dropdown.Toggle>
									<Dropdown.Menu className="dropdown-menu py-0">
										<li className="dropdown-search">
											<div className="d-flex justify-content-between">
												<div className="align-self-center mx-2">
													<div className="card-element mb-0">
														<input type="text" className="p-1" onChange={(e) => this.setState({ TaxSearchString: e.target.value })} />
													</div>
												</div>
												<div className="align-self-center">
													<button className="header-icon card-header-btn ledger-btn mx-2" onClick={() => this.handleOpen('Tax')}>
														<i className="fas fa-plus "></i>
													</button>

												</div>
											</div>
										</li>
										<div id="tax_box">
											{allfilterTax.map(x => {
												return (<li className="p-2 profile-drop-button text-nowrap">
													<div className="d-flex justify-content-between">
														<div className="align-self-center mr-1">{x.tax_name}
															({x.tax_percent}%)</div>
														<div className="align-self-center">

															<button onclick="TaxDetails('gst', 12.0, 49)" className="header-icon ledger-btn mx-1" data-toggle="modal" data-target="#addSection">
																<i className="fas fa-pencil-alt" onClick={(e) => this.getTaxListByID(x.pt_id)}></i>
															</button>
															<button onClick={(e) => {
																this.setState({ TaxID: x.pt_id })
																this.handleChange(e, "DeleteTax")
															}} className="header-icon ledger-btn mx-1">
																<i className="fas fa-trash"></i>
															</button>

														</div>
													</div>
												</li>)
											})}
										</div>
									</Dropdown.Menu>
								</Dropdown>
							</div>
						</div> */}
						{/* Import */}
						{
							importAccess.MenuName == "Import" && importAccess.ViewAccess == 1 ?
								<div className="d-flex h-100 ledger-dropdown">
									<div className="dropdown align-self-center">
										<button className="header-icon card-header-import-btn ledger-btn mx-8" id="import" aria-expanded="false" title="import" onClick={() => this.handleOpen('Import')}>
											<b>Import</b>
										</button>
									</div>
								</div> : null}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;

						{userAccess.MenuName == 'Users' && userAccess.ViewAccess == 1 ?
							<div className="d-flex h-100 ledger-dropdown">
								<div className="dropdown align-self-center">
									<button className="header-icon card-header-btn ledger-btn mx-2" id="ledger" aria-expanded="false" title="Manage Users" onClick={() => this.props.history.push('/user/useraccess')}>
										<i className="fas fa-user-edit"></i>
									</button>
								</div>
							</div>
							: null}

						{/* <div className="d-flex h-100 ledger-dropdown">
							<div className="dropdown align-self-center" onClick={() => { this.props.history.push('/UserAccess/UserAccess') }}>
								<i className="fas fa-user"></i>
							</div>
						</div> */}

					</div>

					<div className="d-flex justify-content-center">
						<div className="header-button-res-block align-self-center">
							<div className="header-icon card-header-btn  mx-2 d-flex justify-content-center" title="Lock Screen">
								<button onClick={() => this.handleLogOut()
								}>
									<i className="fas fa-sign-out-alt align-self-center"></i>
								</button>
							</div>
						</div>
						{/* LockScreen */}
						<div className="header-button-res-block align-self-center">
							<div className="header-icon card-header-btn  mx-2 d-flex justify-content-center" title="Lock Screen">
								<button onClick={() => {
									localStorage.setItem('IsUserLocked', true)
									this.props.history.push('/user/lockscreen')
								}}>
									<i className="fas fa-lock align-self-center"></i>
								</button>
							</div>
						</div>
						{/* FullScreen */}
						<div className="header-button-res-block align-self-center">
							<div className="header-icon card-header-btn  mx-2 d-flex justify-content-center" title="Full Screen">
								<button onClick={(e) => this.handleFullScreen(e)}>
									<i className="fas fa-expand align-self-center"></i>
								</button>
							</div>
						</div>
						{/* Avatar */}
						<div className="header-icon align-self-center ml-2 d-flex justify-content-center">
							<div className="dropdown profile-dropdown align-self-center">
								<Dropdown>
									<Dropdown.Toggle as={CustomToggle}>
										<a href="javascript:;" id="dropBtn" className="d-inline-block mr-2" role="button" data-toggle="dropdown" aria-expanded="false">
											<div className="user-profile align-self-center mx-1">
												<img src={profiledummyimg} alt="User profile" />
											</div> <span className="header-button-res-block user-name pb-2">{UserName}</span>
										</a>
									</Dropdown.Toggle>
									<Dropdown.Menu className="dropdown-menu py-0">
										<li className="header-button-res text-center">{UserName}
											<hr className="my-1" />
										</li>
										<li className="header-button-res profile-drop-button">
											<div className="header-icon align-self-center d-flex justify-content-center text-nowrap">
												<button className="text-left" onClick={() => {
													localStorage.setItem('IsUserLocked', true)
													this.props.history.push('/user/lockscreen')
												}}>
													<i className="mr-2 fas fa-lock align-self-center"></i>
													Lock Screen
												</button>
											</div>
										</li>
										<li className="header-button-res profile-drop-button">
											<div className="header-icon align-self-center d-flex justify-content-center text-nowrap">
												<button className="text-left" onClick={(e) => this.handleFullScreen(e)}>
													<i className="mr-2 fas fa-expand align-self-center"></i>
													Full Screen
												</button>
											</div>
										</li>
										<li className="profile-drop-button">
											<button type="button" className="align-self-center text-left" onClick={() => this.handleOpen('Profile')}>
												<i className="mr-2 fas fa-user"></i>
												Profile
											</button>
										</li>
										<li className="profile-drop-button">
											<button type="button" className="align-self-center text-left" onClick={() => this.handleOpen('ChangePin')}>
												<i className="mr-2 fas fa-user"></i>
												Change Pin
											</button>
										</li>
										<li className="profile-drop-button">
											<button type="button" className="align-self-center text-left" onClick={() => this.handleLogOut()}>
												<i className="mr-2 fas fa-sign-out-alt"></i>
												Log Out
											</button>
										</li>
									</Dropdown.Menu>
								</Dropdown>
							</div>
						</div>
					</div>
				</div>

				{/* Ledger Modal */}
				<Modal Modal size="lg" backdrop="static" show={showLedgerModal} onHide={() => this.handleClose("Ledger")
				} centered>
					<div className="modal-content" id="putModalData">
						<div className="modal-header border-bottom-0">
							<h5 className="modal-title" id="modalHeader">Add Ledger</h5>
							<div className="d-flex header-icon card-header-btn">
								<button type="button" className="align-self-center" aria-label="Close" onClick={() => this.handleClose("Ledger")}>
									<i className="fas fa-times"></i>
								</button>
							</div>
						</div>
						<div className="modal-body h-auto pt-0">
							<form onSubmit={(e) => this.submitLedger(e, this.state.ContactNo, "Add")}>
								<div className="row">
									<div className="col-md-5">
										<div className="card-element">
											<div className="pb-2">
												<label htmlFor="username"> Add Party Name </label>
											</div>
											<input type="text" ref={innerRef => this.innerRef = innerRef} maxlength="50" id="ledger_party_name" value={PartyName} onChange={(e) => this.handleChange(e, "PartyName")} />
										</div>
									</div>
									<div className="col-md-5">
										<div className="card-element">
											<div className="pb-2">
												<label htmlFor="username"> Contact No</label>
											</div>
											<input type="text" min="0" step="1" id="ledger_contact_no" maxlength='10' value={ContactNo} onChange={(e) => this.handleChange(e, "ContactNo")} />
										</div>
									</div>
									<div className="col-md-2 mt-3 text-center d-flex justify-content-center">
										<button type="submit" className=" btn btn-success Submit-btn align-self-center py-1">
											Submit
										</button>
									</div>
								</div>
							</form>
						</div>
					</div>
				</Modal>
				{/* Product Modal */}
				<Modal size="lg" backdrop="static" show={showProductModal} onHide={() => this.handleClose("Product")} centered >
					<div className="modal-content" id="putModalData">
						<div className="modal-header border-bottom-0">
							<h5>Add Product</h5>
							<div className="d-flex header-icon card-header-btn">
								<button type="button" className="align-self-center" aria-label="Close" onClick={() => this.handleClose("Product")}>
									<i className="fas fa-times"></i>
								</button>
							</div>
						</div>
						<div className="modal-body h-auto pt-0">
							<form onSubmit={(e) => this.submitProduct(e, "Add")}>
								<div className="row">
									<div className="col-md-5">
										<div className="card-element">
											<div className="pb-2">
												<label htmlFor="username"> Add Product Name </label>
											</div>
											<input type="text" ref={innerRef => this.innerRef = innerRef} id="product_name" value={ProductName} onChange={(e) => this.handleChange(e, "ProductName")} />
										</div>
									</div>
									<div className="col-md-5">
										<div className="card-element">
											<div className="pb-2">
												<label htmlFor="username"> Add Product Id</label>
											</div>
											<input type="text" id="product_id" value={ProductNo} onChange={(e) => this.handleChange(e, "ProductNo")} />
										</div>
									</div>
									<div className="col-md-2 mt-3 text-center d-flex justify-content-center">
										<button type="submit" className="btn btn-success Submit-btn align-self-center py-1">
											Submit
										</button>
									</div>
								</div>
							</form>
						</div>
					</div>
				</Modal>
				{/* Tax Modal */}
				<Modal size="lg" backdrop="static" show={showTaxModal} onHide={() => this.handleClose("Tax")} centered >
					<div className="modal-content" id="putModalData">
						<div className="modal-header border-bottom-0">
							<h5 className="modal-title" id="modalHeader">Add Tax</h5>
							<div className="d-flex header-icon card-header-btn">
								<button type="button" className="align-self-center" aria-label="Close" onClick={() => this.handleClose("Tax")}>
									<i className="fas fa-times"></i>
								</button>
							</div>
						</div>
						<div className="modal-body h-auto pt-0">
							<form onSubmit={(e) => this.submitTax(e, "Add")}>
								<div className="row">
									<div className="col-md-5">
										<div className="card-element">
											<div className="pb-2">
												<label htmlFor="username">Add Tax Name</label>
											</div>
											<input type="text" ref={innerRef => this.innerRef = innerRef} maxlength="10" id="tax_name" value={TaxName} onChange={(e) => this.handleChange(e, "TaxName")} />
										</div>
									</div>
									<div className="col-md-5">
										<div className="card-element">
											<div className="pb-2">
												<label htmlFor="username"> Add Tax Percentage</label>
											</div>
											<input type="text" min="0" step="1" oninput="handleChange(this)" id="tax_percentage" value={TaxPercentage} onChange={(e) => this.handleChange(e, "TaxPercentage")} />
										</div>
									</div>
									<div className="col-md-2 mt-3 text-center d-flex justify-content-center">
										<button type="submit" className="btn btn-success Submit-btn align-self-center py-1">
											Submit
										</button>
									</div>
								</div>
							</form>
						</div>
					</div>
				</Modal>
				{/* Import Modal */}
				<Modal size="lg" backdrop="static" show={showImportModal} onHide={() => this.handleClose("Import")} centered >
					<div class="modal-content" id="putModalData">
						<form enctype="multipart/form-data" class="dropzone" id="uploadExcelForm" onSubmit={(e) => this.bulkImportEntry(e)}>
							<div class="modal-header border-bottom-0">
								<h5 class="modal-title" id="modalHeader">
									Data Import <a href="https://v2.rojmed.co.in:4321/sample_excel.xlsx" download>Download Sample File</a>
								</h5>
								<div class="d-flex header-icon card-header-btn">
									<button type="button" class="align-self-center" aria-label="Close" onClick={() => this.handleClose("Import")}>
										<i class="fas fa-times"></i>
									</button>
								</div>
							</div>
							<div class="modal-body h-auto pt-0">
								<div class="row">
									<div class="col-md-5">
										<div class="card-element">
											<div class="pb-2">
												<label for="Uploadfile">Upload File<span className="text-danger"> *</span></label>
											</div>
											<div class="file-upload-wrapper">
												<input type="file" ref={fileImp => this.fileImp = fileImp} name="file" id="file_import" class="file-upload" accept=".xlsx" onChange={this.fileHandler.bind(this)} />
											</div>
										</div>
									</div>
									<div class="col-md-5">
										<div class="card-element">
											<div class="pb-2">
												<label for="username">Add Party Name<span className="text-danger"> *</span></label>
											</div>
											{/* <select value={this.state.PartyID} onChange={(e) => this.setState({ PartyID: e.target.value })} className="form-control" id="filter_party_name_res" style={{ height: "2.79rem" }}>
												<option value="">Select Party</option>

												{LedgerList.map(x => {
													return (<option class="select-option" value={x.ledger_user_id}>
														{x.ledger_username} ({x.ledger_user_id})</option>
													)
												})}
											</select> */}
											<Select
												className="select2-hidden-accessible"
												classNamePrefix="select"
												placeholder="Select Party"
												value={this.state.PartyID}
												isClearable={true}
												isSearchable={true}
												onChange={(e) => this.setState({ PartyID: e })}
												//   name="color"
												options={LedgerList.length ? LedgerList.map(x => { return { value: x.ledger_user_id, label: x.ledger_username + "(" + x.ledger_user_id + ")" } }) : []}
											/>
										</div>
									</div>
									<div class="col-md-2 mt-3 text-center d-flex justify-content-center">
										<button type="submit" class="btn btn-success Submit-btn align-self-center py-1">Submit</button>
									</div>
								</div>
							</div>
						</form>
					</div>
				</Modal >


				{/* Ledger Modal Edit*/}
				<Modal size="lg" backdrop="static" show={showEditLedgerModal} onHide={() => this.handleClose("EditLedger")} centered >
					<div className="modal-content" id="putModalData">
						<div className="modal-header border-bottom-0">
							<h5 className="modal-title" id="modalHeader">Edit Ledger</h5>
							<div className="d-flex header-icon card-header-btn">
								<button type="button" className="align-self-center" aria-label="Close" onClick={() => this.handleClose("EditLedger")}>
									<i className="fas fa-times"></i>
								</button>
							</div>
						</div>
						<div className="modal-body h-auto pt-0">
							<form onSubmit={(e) => this.submitLedger(e, this.state.ContactNo, "Update")}>
								<div className="row">
									<div className="col-md-5">
										<div className="card-element">
											<div className="pb-2">
												<label htmlFor="username"> Add Party Name </label>
											</div>
											<input type="text" ref={innerRef => this.innerRef = innerRef} maxlength="50" id="ledger_party_name" value={PartyName} onChange={(e) => this.handleChange(e, "PartyName")} />
										</div>
									</div>
									<div className="col-md-5">
										<div className="card-element">
											<div className="pb-2">
												<label htmlFor="username"> Contact No</label>
											</div>
											<input type="text" min="0" step="1" id="ledger_contact_no" value={ContactNo} onChange={(e) => this.handleChange(e, "ContactNo")} />
										</div>
									</div>
									<div className="col-md-2 mt-3 text-center d-flex justify-content-center">
										<button type="submit" className=" btn btn-success Submit-btn align-self-center py-1">
											Submit
										</button>
									</div>
								</div>
							</form>
						</div>
					</div>
				</Modal >
				{/* Product Modal Edit */}
				<Modal size="lg" backdrop="static" show={showEditProductModal} onHide={() => this.handleClose("EditProduct")} centered >
					<div className="modal-content" id="putModalData">
						<div className="modal-header border-bottom-0">
							<h5>Edit Product</h5>
							<div className="d-flex header-icon card-header-btn">
								<button type="button" className="align-self-center" aria-label="Close" onClick={() => this.handleClose("EditProduct")}>
									<i className="fas fa-times"></i>
								</button>
							</div>
						</div>
						<div className="modal-body h-auto pt-0">
							<form onSubmit={(e) => this.submitProduct(e, "Update")}>
								<div className="row">
									<div className="col-md-5">
										<div className="card-element">
											<div className="pb-2">
												<label htmlFor="username"> Add Product Name </label>
											</div>
											<input type="text" ref={innerRef => this.innerRef = innerRef} id="product_name" value={ProductName} onChange={(e) => this.handleChange(e, "ProductName")} />
										</div>
									</div>
									<div className="col-md-5">
										<div className="card-element">
											<div className="pb-2">
												<label htmlFor="username"> Add Product Id</label>
											</div>
											<input type="text" ref={innerRef => this.innerRef = innerRef} id="product_id" value={ProductNo} onChange={(e) => this.handleChange(e, "ProductNo")} />
										</div>
									</div>
									<div className="col-md-2 mt-3 text-center d-flex justify-content-center">
										<button type="submit" className="btn btn-success Submit-btn align-self-center py-1">
											Submit
										</button>
									</div>
								</div>
							</form>
						</div>
					</div>
				</Modal>
				{/* Tax Modal Edit */}
				<Modal size="lg" backdrop="static" show={showEditTaxModal} onHide={() => this.handleClose("EditTax")} centered >
					<div className="modal-content" id="putModalData">
						<div className="modal-header border-bottom-0">
							<h5 className="modal-title" id="modalHeader">Edit Tax</h5>
							<div className="d-flex header-icon card-header-btn">
								<button type="button" className="align-self-center" aria-label="Close" onClick={() => this.handleClose("EditTax")}>
									<i className="fas fa-times"></i>
								</button>
							</div>
						</div>
						<div className="modal-body h-auto pt-0">
							<form onSubmit={(e) => this.submitTax(e, "Update")}>
								<div className="row">
									<div className="col-md-5">
										<div className="card-element">
											<div className="pb-2">
												<label htmlFor="username">Add Tax Name</label>
											</div>
											<input type="text" ref={innerRef => this.innerRef = innerRef} maxlength="10" id="tax_name" value={TaxName} onChange={(e) => this.handleChange(e, "TaxName")} />
										</div>
									</div>
									<div className="col-md-5">
										<div className="card-element">
											<div className="pb-2">
												<label htmlFor="username"> Add Tax Percentage</label>
											</div>
											<input type="text" min="0" step="1" oninput="handleChange(this)" id="tax_percentage" value={TaxPercentage} onChange={(e) => this.handleChange(e, "TaxPercentage")} />
										</div>
									</div>
									<div className="col-md-2 mt-3 text-center d-flex justify-content-center">
										<button type="submit" className="btn btn-success Submit-btn align-self-center py-1">
											Submit
										</button>
									</div>
								</div>
							</form>
						</div>
					</div>
				</Modal>

				{/* Profile Modal */}
				<Modal show={showProfileModal} dialogClassName="full-screen-modal modal-dialog-centered" className="right profile-modal" onHide={() => this.handleClose("Profile")}>

					<div className="modal-header pb-1 border-bottom-0">
						<h5 className="modal-title">Profile</h5>
						<div className="d-flex header-icon card-header-btn">
							<button type="button" className="align-self-center" aria-label="Close" onClick={() => {
								this.handleClose("Profile")
								this.setState({ showPasswordChange: false })
								this.setState({ showProfile: true })
							}}>
								<i className="fas fa-times"></i>
							</button>
						</div>
					</div>
					<div className="modal-body pt-0">
						<div className="profile-section">

							<div className="base-profile-user">
								<div className="profile-img-user" id="setimg" style={{ backgroundImage: `url(${profiledummyimg})` }}
								>
								</div>
							</div>
							<div className="d-flex justify-content-center">
								<div className="profile-information" style={showProfile === true ? { display: "block" } : showPasswordChange === true ? { display: "none" } : null}>

									<div className="basic-info">
										<label className="mr-2">Username:</label> {UserName}
									</div>
									<div className="basic-info">
										<label className="mr-2">Contact No:</label> {Contact_No}
									</div>
									<div className="basic-info">
										<label className="mr-2">Start Date:</label>
										<span> {Start_Date ? moment(Start_Date).format('DD MMM YYYY') : ''}</span>
									</div>
									<div className="basic-info">
										<label className="mr-2">End Date:</label>
										<span> {end_Date ? moment(end_Date).format('DD MMM YYYY') : ''}</span>
									</div>
									<div className="basic-info">
										<label className="mr-2">Subscription Plan:</label>
										{Sub_plan}
									</div>

								</div>
							</div>
							<div className="text-center header-icon" style={showProfile === true ? { display: "block" } : { display: "none" }}>
								<button type="button" className="btn-hover-effect w-auto" id="changePassword" onClick={() => {
									this.setState({ showPasswordChange: true })
									this.setState({ showProfile: false })
								}}>
									<i className="fas fa-lock"></i> Change Password
								</button>
							</div>

							<div className="text-center header-icon mb-3" style={showPasswordChange === true ? { display: "block" } : showProfile === true ? { display: "none" } : null}>
								<button type="button" className="btn-hover-effect w-auto" id="changePassword" onClick={() => {
									this.setState({ showPasswordChange: false })
									this.setState({ showProfile: true })
								}}>
									<i className="fas fa-user"></i> Profile
								</button>
							</div>
							<div id="passwordArea" className="change-password" style={showPasswordChange === true ? { display: "block" } : null}>
								<div className="card-element">
									<div className="pb-2">
										<label className="username">Current Password</label>
									</div>
									<input type="password" value={CurrentPassword} onChange={(e) => this.handlePwdChange(e, "CurrentPassword")} id="password" />
								</div>
								<div className="card-element">
									<div className="pb-2">
										<label className="username">New Password</label>
									</div>
									<input type="password" id="new_pass" value={NewPassword} onChange={(e) => this.handlePwdChange(e, "NewPassword")} id="password" />
								</div>
								<div className="card-element">
									<div className="pb-2">
										<label className="username">Confirm New Password</label>
									</div>
									<input type="password" id="new_confirm_pass" value={ConfirmPassword} onChange={(e) => this.handlePwdChange(e, "ConfirmPassword")} id="password" />
								</div>
								<div className="text-center">
									<button type="button" className="Submit-btn my-2" onClick={(e) => this.handleChangePassword(e)} id="change_passsword_submit">
										Submit</button>
								</div>
							</div>

						</div>
					</div>

				</Modal>

				{/*  Product Statement Modal start  */}
				<Modal show={showProductStatementModal} dialogClassName="full-screen-modal" className="statement-modal full-screen-modal" onHide={() => this.handleClose("ProductStatement")}>
					{/* <div class="modal-dialog modal-dialog-centered modal-lg">

						<div class="modal-content"> */}
					<div class="modal-header pb-1 border-bottom-0" >
						<h5 class="modal-title" id="product_summary_date">{ProductStatementArr.length ? ProductStatementArr[0].product_name : ""}
						</h5>
						<div class="d-flex">
							<div class="d-flex header-icon card-header-btn">
								<button type="button" class="align-self-center" data-dismiss="modal" aria-label="Close" onClick={() => this.handleClose("ProductStatement")}>
									<i class="fas fa-times"></i>
								</button>
							</div>
						</div>
					</div>
					<div class="modal-body pt-0">
						{/* <div class="table-responsive product-table "> */}
						<div class="table-area">
							<div class="statement-table">
								<table class="table table-striped borderless">
									<thead>
										<tr>
											<th className="table-first"></th>
											<th className="table-first">Date & Time</th>
											<th className="table-second">Sales	</th>
											<th className="table-third">Purchase</th>
											<th className="table-third">Rate</th>
											<th className="table-third">Party</th>
											<th className="table-fifth-th" scope="col">Description</th>
										</tr>
									</thead>
									<tbody id="Detailed_product_summary">
										{ProductStatementArr.length && !CommonConfig.isEmpty(ProductStatementArr[0].CreatedOn) && !CommonConfig.isEmpty(ProductStatementArr[0].p_id) ? ProductStatementArr.map((x, idx) => {
											return (

												<tr>
													<td className="table-first">
														{/* <input type="checkbox" value={this.state.checked} onChange={() => {
														this.setState(prevState => ({
															checked: !prevState.checked
														}));
														this.handleBalanceData(x.Purchase, !this.state.checked)
													}} /> */}

														<input
															type="checkbox"
															value={this.state.checked}
															onClick={(e) => {


																this.handleBalanceData(e, x.Purchase, x.Sales);
															}}
														/>
													</td>
													<td className="table-first">
														{x.CreatedOn ? moment(x.CreatedOn).format('DD MMM YYYY') : ''}</td>
													<td className="table-second">
														{x.Sales}</td>
													<td className="table-third">
														{x.Purchase}</td>
													<td className="table-third">
														{x.rate}</td>
													<td className="table-third">
														{x.PartyName}</td>
													<td className="table-fifth-th">
														{x.Description}</td>

												</tr>

											)
										}) : <tr class="text-center font-weight-bold"><td>No record found.</td></tr>}
										<tr class="statement-footer1 font-weight-bolder border-top">
											<td class="table-first">Total</td>
											<td class="table-second">{this.state.salesTotal ? this.state.salesTotal : ProductStatementArr.length ? parseFloat(ProductStatementArr[0].TotalSales).toFixed(2) : parseFloat(0).toFixed(2)}</td>
											<td class="table-third">{this.state.total ? this.state.total : ProductStatementArr.length ? parseFloat(ProductStatementArr[0].TotalPurchase).toFixed(2) : parseFloat(0).toFixed(2)}</td>
											<td class="table-fourth"></td>
											<td class="table-fifth"></td>
										</tr>
										<tr class="thead-dark statement-footer2 font-weight-bolder" colspan="4">
											<td class="table-first">Grand Total</td>
											<td class="credit-color table-second"><span>{(this.state.total - this.state.salesTotal) ? (this.state.total - this.state.salesTotal) : ProductStatementArr.length ? parseFloat(ProductStatementArr[0].TotalSales).toFixed(2) - parseFloat(ProductStatementArr[0].TotalPurchase).toFixed(2) < 0 ? parseFloat(parseFloat(ProductStatementArr[0].TotalPurchase).toFixed(2) - parseFloat(ProductStatementArr[0].TotalSales).toFixed(2)).toFixed(2) : '' : ''}</span></td>
											<td class="debit-color table-third"><span>{ProductStatementArr.length ? !CommonConfig.isEmpty(ProductStatementArr[0].CreatedOn) && !CommonConfig.isEmpty(ProductStatementArr[0].p_id) ? parseFloat(ProductStatementArr[0].TotalSales).toFixed(2) - parseFloat(ProductStatementArr[0].TotalPurchase).toFixed(2) > 0 ? parseFloat(parseFloat(ProductStatementArr[0].TotalSales).toFixed(2) - parseFloat(ProductStatementArr[0].TotalPurchase).toFixed(2)).toFixed(2) : '' : parseFloat(0).toFixed(2) : ''}</span></td>
											<td class="table-fourth"></td>
											<td class="table-fifth"></td>
										</tr>
									</tbody>
								</table>
							</div>
						</div>
					</div>

					{/* </div>
					</div> */}
				</Modal>
				{/*  Product Statement Modal end  */}

				{/* {this.state.IsDashboardSelected ? <Dashboard listid={168} /> : null} */}


				<Modal Modal size="lg" backdrop="static" show={showChangePinModal} onHide={() => this.handleClose("ChangePin")
				} centered>
					<div className="modal-content" id="putModalData">
						<div className="modal-header border-bottom-0">
							<h5 className="modal-title" id="modalHeader">Change Pin</h5>
							<div className="d-flex header-icon card-header-btn">
								<button type="button" className="align-self-center" aria-label="Close" onClick={() => this.handleClose("ChangePin")}>
									<i className="fas fa-times"></i>
								</button>
							</div>
						</div>
						<div className="modal-body h-auto pt-0">
							<form onSubmit={(e) => this.changePin(e)}>
								<div className="row">
									<div className="col-md-5">
										<div className="card-element">
											<div className="pb-2">
												<label htmlFor="username"> Old Pin </label>
											</div>
											<input type="text" maxlength="4" id="oldpin" value={oldPin} onChange={(e) => this.handleChange(e, "oldPin")} />
										</div>
									</div>
									<div className="col-md-5">
										<div className="card-element">
											<div className="pb-2">
												<label htmlFor="username"> New Pin</label>
											</div>
											<input type="text" min="0" step="1" id="newpin" maxlength='4' value={newPin} onChange={(e) => this.handleChange(e, "newPin")} />
										</div>
									</div>
									<div className="col-md-2 mt-3 text-center d-flex justify-content-center">
										<button type="submit" className=" btn btn-success Submit-btn align-self-center py-1">
											Submit
										</button>
									</div>
								</div>
							</form>
						</div>
					</div>
				</Modal>
			</div>
		)
	}
}
