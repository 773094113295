import React, { Component } from 'react';
import logo from '../../assets/img/AccountingLogo.png';
import api from "../../utils/apiClient";
import '../../assets/css/style.scss';
import CommonConfig from '../../utils/constant';
import Swal from 'sweetalert2';
import Recaptcha from 'react-recaptcha';

const initialState = {
    UserName: '',
    Password: '',
    alert: null,
    IsFirstTimeLoggedIn: false,
    DBName: '',
    ConfirmPassword: '',
    SetPin: '',
    ConfirmPin: '',
    Step: 1,
    ContactNo: '',
    verifyHelpertext: '',
    robotVerifyErr: false,
    isVerifiedErr: false,
    isVerifiedErrText: '',
    isVerified: false,
    reCaptchaResponse: null,
    isUserVerified: false
}

const SITE_KEY = "6Ldpgd0ZAAAAADzGdKNDFrON5N_InsmaYHc8IlyG";
export default class Login extends Component {
    constructor(props) {
        super(props);
        this.state = initialState;

        this.recaptchaLoaded = this.recaptchaLoaded.bind(this);
        this.verifyCallback = this.verifyCallback.bind(this);
    }

    componentDidMount() {
        if (CommonConfig.loginData() && localStorage.getItem("isAuthorized")) {
            this.props.history.push('/user/dashboard')
        }
        const loadScriptByURL = (id, url, callback) => {
            const isScriptExist = document.getElementById(id);

            if (!isScriptExist) {
                var script = document.createElement("script");
                script.type = "text/javascript";
                script.src = url;
                script.id = id;
                script.onload = function () {
                    if (callback) callback();
                };
                document.body.appendChild(script);
            }

            if (isScriptExist && callback) callback();
        }

        // load the script by passing the URL
        loadScriptByURL("recaptcha-key", `https://www.google.com/recaptcha/api.js?render=${SITE_KEY}`, function () {
            console.log("Script loaded!");
        });




        // }
    }

    validate = () => {
        // window.grecaptcha.ready(() => {
        //     window.grecaptcha.execute(SITE_KEY, { action: 'submit' }).then(token => {
        //         this.verifyCallback(token);
        //     });
        // });

        let formIsValid = true;

        if (this.state.UserName === "" || this.state.UserName === null) {
            this.setState({ UserNameErr: true, UserhelperText: 'UserName is required' });
            formIsValid = false;
        }

        // else if (!this.state.UserName.match(CommonConfig.RegExp.email)) {
        //   this.setState({ UserNameErr: true, UserhelperText: 'Email  is not valid' });
        //   Toast.error({ message: 'Invalid credentials' });
        //   formIsValid = false;
        // }
        else {
            this.setState({ UserNameErr: false, UserhelperText: '' });
        }

        if (this.state.Password === "" || this.state.Password === null) {
            this.setState({ PasswordErr: true, PasswordhelperText: 'Password is required' });
            formIsValid = false;
        }
        else {
            this.setState({ PasswordErr: false, PasswordhelperText: '' });
        }

        if (this.state.isVerified) {
            this.setState({ isVerifiedErr: false });
        }
        else {
            this.setState({ isVerifiedErr: true });
            formIsValid = false;
        }


        return formIsValid;
    }
    handleLogin = (e) => {
        e.preventDefault();
        if (this.validate()) {
            let data = {
                UserName: this.state.UserName,
                Password: this.state.Password,
                UserType: "role_user"
            }
            try {
                api.post("authentication/userLogin", data).then(res => {
                    if (res.success) {
                        CommonConfig.showMessage(res.message, res.messagetype);
                        localStorage.setItem('loginData', JSON.stringify(res.data));
                        localStorage.setItem('isAuthorized', res.success);
                        setTimeout(() => {
                            this.setState({ alert: null })
                            this.props.history.push('/user/dashboard');
                        }, 3000);

                        // window.location.href = window.location.origin + '/user/dashboar'
                    } else {
                        if (!CommonConfig.isObjectEmpty(res.data) && CommonConfig.isEmpty(res.data.last_login)) {
                            this.setState({ IsFirstTimeLoggedIn: true, ContactNo: res.data.contact_no })
                        }
                        CommonConfig.showMessage(res.message, res.messagetype);
                    }
                }).catch(err => { })
            }
            catch (err) { }

        }
    }
    submitLoginSetup = (e) => {
        e.preventDefault();

        let data = {
            UserID: this.state.ContactNo,
            CurrentPassword: this.state.Password,
            NewPassword: this.state.ConfirmPassword,
            Pin: this.state.SetPin === this.state.ConfirmPin ? this.state.ConfirmPin : " ",
            DB_Name: this.state.DBName,

        }
        try {
            api.post("authentication/LoginSetup", data).then(res => {

                if (res.success) {
                    CommonConfig.showMessage(res.data[0].vMessage, res.data[0].vMessageType);
                    this.setState(initialState)
                } else {
                    CommonConfig.showMessage(res.data[0].vMessage, res.data[0].vMessageType);
                }
            }).catch(err => { })
        }
        catch (err) { }
    }

    handleChange = (e, type) => {
        if (type === "UserName") {
            this.setState({ UserName: e.target.value })
        }
        if (type === "Password") {
            this.setState({ Password: e.target.value })
        }
        if (type === "DBName") {
            this.setState({ DBName: e.target.value })
        }
        if (type === "ConfirmPassword") {
            this.setState({ ConfirmPassword: e.target.value })
        }
        if (type === "SetPin") {
            this.setState({ SetPin: e.target.value })
        }
        if (type === "ConfirmPin") {
            this.setState({ ConfirmPin: e.target.value })
        }
    }

    setup = (type) => {
        if (type === "Next") {
            this.setState({ Step: this.state.Step + 1 })
        } else if (type === "Previous") {
            this.setState({ Step: this.state.Step - 1 })
        }
    }

    recaptchaLoaded = () => {
        if (this.state.isVerified === true) {
            this.setState({ isVerified: true, isVerifiedErr: false, isVerifiedErrText: '' });
        }
        else {
            // this.setState({ isVerified: false });
            // this.setState({ isVerifiedErr: true, isVerifiedErrText: 'Please verify that your are human' });
        }
    }
    handlerforenter1 = (event) => {
        if (event.charCode === 13) {
            const form = event.target.form;
            const index = Array.prototype.indexOf.call(form, event.target);
            form.elements[index + 2].focus();
            event.preventDefault();
        }
    }
    verifyCallback = (response) => {
        this.setState({ reCaptchaResponse: response })
        if (response) {
            this.setState({ isVerified: true, isVerifiedErr: false, isVerifiedErrText: '' });
        }
        else {
            this.setState({ isVerified: false, isVerifiedErr: true, isVerifiedErrText: 'Please verify that your are human' });
        }

    }

    handleEnter1(event) {
        if (event.keyCode === 13) {
            const form = event.target.form;
            const index = Array.prototype.indexOf.call(form, event.target);
            form.elements[index + 2].focus();
            event.preventDefault();
        }
    }
    render() {
        const { UserName, verifyHelpertext, robotVerifyErr, Password, IsFirstTimeLoggedIn, DBName, ConfirmPassword, SetPin, ConfirmPin, Step } = this.state;
        return (
            <div className="d-flex h-100">
                {/* {!IsFirstTimeLoggedIn ? */}
                <section style={IsFirstTimeLoggedIn ? { transform: "translateX(-1000px)" } : { transform: "translateX(0px)" }}>
                    <div className="d-flex h-100">
                        <div className="w-100 align-self-center">
                            <div className="login-logo">
                                <img src={logo} alt="logo" />
                            </div>
                            <p>Login</p>
                            <form className="user-login" onSubmit={(e) => this.handleLogin(e)}>
                                <div className="form-group">
                                    <label htmlFor="userName">User Name :</label>
                                    <input type="text" value={UserName} onChange={(e) => this.handleChange(e, "UserName")} id="username" autoFocus="autoFocus" />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="passWord">Password :</label>
                                    <input type="passWord" value={Password} onChange={(e) => this.handleChange(e, "Password")} id="password" />
                                </div>

                                {/* <span className="input-icn"><i class="fa fa-refresh" aria-hidden="true"></i></span>

                                    <Recaptcha sitekey="6Lc1f8ckAAAAAEC-VoCdnb1Ykq1tkBVHkTWeXd7o" render="explicit"
                                        onloadCallback={() => this.recaptchaLoaded()}
                                        onKeyDown={() => this.handlerforenter1()}
                                        verifyCallback={() => this.verifyCallback()}
                                        helperText={this.state.verifyHelpertext}
                                    />*/}
                                {/* {robotVerifyErr && <h5>Please verify that you are human</h5>} */}
                                <div>
                                    <Recaptcha sitekey="6Ldpgd0ZAAAAADzGdKNDFrON5N_InsmaYHc8IlyG" render="explicit"
                                        onloadCallback={this.recaptchaLoaded}
                                        onKeyDown={this.handleEnter1}
                                        verifyCallback={this.verifyCallback}
                                        helperText={this.state.isVerifiedErrText}
                                    />
                                    <input type="hidden" name="token" id="token" />
                                    <input type="hidden" name="action" id="action" />
                                    {this.state.isVerifiedErr && <div className="p-text-center" style={{ color: 'red' }}> Please verify that you are human</div>}
                                </div>



                                <div className="form-group">
                                    <button type="submit" id="login-btn" className="loginfrontbtn" value="Login">Login </button>
                                </div>
                            </form>
                        </div>
                    </div>
                    {this.state.alert}
                </section>
                {/* : */}

                <section style={IsFirstTimeLoggedIn && Step === 1 ? { transform: "translateX(0px)" } : { transform: "translateX(1000px)", display: "none" }}>
                    <form className="user-login" >
                        <div class="d-flex h-100">
                            <div class="w-100 align-self-center">
                                <p>Step 1 :</p>
                                <div class="form-group">
                                    <label for="DBName">Database Name :</label>
                                    <input type="text" id="DBName" value={DBName} onChange={(e) => this.handleChange(e, "DBName")} />
                                </div>
                                <div class="form-group">
                                    <label for="cirmPassWord">Confirm Password :</label>
                                    <input type="passWord" value={ConfirmPassword} onChange={(e) => this.handleChange(e, "ConfirmPassword")} />
                                    <div id="confirm_pass_error" class="invalid-feedback"></div>
                                </div>
                                <div class="row align-items-center">
                                    <div class="col-9 d-flex align-items-center">
                                        <span>Answer These security questions.</span>
                                        <i class="fas fa-chevron-double-right"></i>
                                    </div>
                                    <div class="col-3">
                                        <button type="button" class="loginbtn mb-0" style={{ marginTop: "13px", dataBsToggle: "modal", dataBsTarget: "#QuestionModal" }} id="questionModel">
                                            <i class="far fa-question-circle" style={{ fontSize: "24px" }}></i>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </section>

                <section id="pin_section" style={IsFirstTimeLoggedIn && Step === 2 ? { transform: "translateX(0px)" } : { transform: "translateX(1000px)", display: "none" }}>
                    <form className="user-login" >
                        <div class="d-flex h-100">
                            <div class="w-100 align-self-center">
                                <p>Step 2 :</p>
                                <div class="form-group">
                                    <label for="SetPin">Set Pin :</label>
                                    <input type="text" id="SetPin" value={SetPin} onChange={(e) => this.handleChange(e, "SetPin")} />
                                </div>
                                <div class="form-group">
                                    <label for="CirmPin">Confirm Pin :</label>
                                    <input type="passWord" id="CirmPin" value={ConfirmPin} onChange={(e) => this.handleChange(e, "ConfirmPin")} />
                                    <div id="CirmPin_error" class="invalid-feedback"></div>
                                </div>
                            </div>
                        </div>
                    </form>
                </section>

                <div style={IsFirstTimeLoggedIn ? { display: "flex", alignItems: "center", justifyContent: "flex-end" } : { display: "none" }}>
                    {Step !== 1 ?
                        <div class="button" onClick={() => this.setup("Previous")} id="prev">← Previous</div>
                        :
                        null}
                    {Step !== 2 ?
                        <div class="button" onClick={() => this.setup("Next")} id="next">Next →</div>
                        :
                        <div class="button" id="submit" onClick={(e) => this.submitLoginSetup(e)}>Submit</div>
                    }
                </div>
                {/* } */}
            </div>
        )
    }
}



